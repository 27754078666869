@import '../../variables.scss';

.suggestions-page{
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-family: $heading-stack;
        font-weight: bold;
        margin: 30px 0px 20px 0px;
    }

    p{
        font-size: 16px;
        margin: 0px 10vw;
        color: rgba(0,0,0,0.6);
        margin-bottom: 20px;
    }

}

.ai-event-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 30px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    width: 70%;
    margin: 10px 0px;
    box-shadow: -4px 4px 5px rgba(0,0,0,0.1);
    .heading-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .container-row{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .container-icon{
            font-size: 20px;
            color: rgba(0,0,0,0.4);
        }
    
        h3{
            margin: 0 10px;
            padding: 0;
            font-weight: bold;
        }
        .email{
            margin: 0;
            padding: 0;
            font-size: 12px;
        }
        .description{
            margin: 15px 0px;
            // background: yellow;
        }
    }
}